import { tuiRound } from '@taiga-ui/cdk';
import { TUI_FLOATING_PRECISION } from '@taiga-ui/kit/constants';

/**
 * @internal
 */
function tuiHorizontalDirectionToNumber(direction) {
    switch (direction) {
        case 'left':
            return -1;
        case 'right':
            return 1;
    }
}

function tuiFindKeyStepsBoundariesByFn(keySteps, fn) {
    const keyStepUpperIndex = keySteps.findIndex((keyStep, i) => i && fn(keyStep));
    const lowerStep = keySteps[keyStepUpperIndex - 1] || keySteps[0];
    const upperStep = keySteps[keyStepUpperIndex] || keySteps[keySteps.length - 1];
    return [lowerStep, upperStep];
}
function tuiPercentageToKeyStepValue(valuePercentage, keySteps) {
    const [[lowerStepPercent, lowerStepValue], [upperStepPercent, upperStepValue]] = tuiFindKeyStepsBoundariesByFn(keySteps, ([keyStepPercentage, _]) => valuePercentage <= keyStepPercentage);
    const ratio = (valuePercentage - lowerStepPercent) / (upperStepPercent - lowerStepPercent);
    const controlValue = (upperStepValue - lowerStepValue) * ratio + lowerStepValue;
    return tuiRound(controlValue, TUI_FLOATING_PRECISION);
}
function tuiKeyStepValueToPercentage(value, keySteps) {
    const [[lowerStepPercent, lowerStepValue], [upperStepPercent, upperStepValue]] = tuiFindKeyStepsBoundariesByFn(keySteps, ([_, keyStepValue]) => value <= keyStepValue);
    const ratio = (value - lowerStepValue) / (upperStepValue - lowerStepValue) || 0;
    return (upperStepPercent - lowerStepPercent) * ratio + lowerStepPercent;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiHorizontalDirectionToNumber, tuiKeyStepValueToPercentage, tuiPercentageToKeyStepValue };

